<template>
  <div>
    <b-table class="table is-fullwidth" :data="companyDocuments" paginated per-page="5">
      <b-table-column sortable label="Documento" field="tipo" v-slot="props">
        <a :href="props.row.file | fileServer" download>{{ props.row.type }}</a>
      </b-table-column>

      <b-table-column
        centered
        sortable
        field="criado"
        label="Inserido em"
        width="140"
        v-slot="props"
        >{{ new Date(props.row.createdAt).toLocaleDateString('pt-BR') }}</b-table-column
      >

      <b-table-column
        centered
        sortable
        field="validade"
        label="Validade"
        width="120"
        v-slot="props"
        :visible="displayValidity"
      >
        <span
          v-if="props.row.validity !== null"
          class="tag"
          :class="validDocument(props.row.validity) ? 'is-success' : 'is-danger'"
          >{{ new Date(props.row.validity).toLocaleDateString('pt-BR') }}</span
        >
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { fileServer } from '@/utils/functions';

export default {
  name: 'ViewCompanyDocuments',
  filters: {
    fileServer,
  },
  computed: {
    ...mapState('companies', ['companyHistory']),
    ...mapState('companiesDocuments', ['companyDocuments']),
    displayValidity() {
      return this.companyDocuments.some(documento => documento.validity);
    },
  },
  watch: {
    companyHistory() {
      if (this.companyHistory.id !== 0) {
        this.fetchCompanyDocuments(this.companyHistory.companyId);
      }
    },
  },
  methods: {
    ...mapActions('companiesDocuments', ['fetchCompanyDocuments']),
    validDocument(date) {
      const now = new Date();
      const documentDate = new Date(date);
      return now <= documentDate;
    },
  },
};
</script>
